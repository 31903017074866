/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
// import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Stack from "@mui/material/Stack";
// import Icon from "@mui/material/Icon";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Material Kit 2 React components
import MKBox from "components/MKBox";
// import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Presentation page components
// import ExampleCard from "pages/Presentation/components/ExampleCard";

// Data
// import data from "pages/Presentation/sections/data/pagesData";

function Projects() {
  // const renderData = data.map(({ image, name, route }) => (
  //   <Grid item xs={12} md={6} sx={{ mb: { xs: 3, lg: 0 } }} key={name}>
  //     <Link to={route}>
  //       <ExampleCard image={image} name={name} display="grid" minHeight="auto" />
  //     </Link>
  //   </Grid>
  // ));

  return (
    <MKBox component="section" py={2}>
      {/* <Container> */}
      {/* <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 2, mx: "auto", px: 0.75 }}
        > */}
      {/* <MKBadge
            variant="contained"
            color="info"
            badgeContent="Fundrazor 101"
            container
            sx={{ mb: 2 }}
          /> */}
      {/* <MKTypography variant="h2" fontWeight="bold">
            Types of Projects
          </MKTypography>
          <MKTypography variant="body1" color="text">
            Supporting different types of projects on our platform
          </MKTypography>
        </Grid>
      </Container> */}
      <Container sx={{ mt: { xs: 6, lg: 6 } }}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
              <MKTypography variant="h3" fontWeight="bold" mb={1}>
                What projects can list on Fundrazor?
              </MKTypography>
              <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
                We support various types of projects that are looking for funding. Each project type
                offers different risk to reward ratio!
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={9} sx={{ mt: 3, px: { xs: 0, lg: 8 } }}>
            <Grid container spacing={2}>
              {/* {renderData} */}
              <Container>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <FilledInfoCard
                      // variant="gradient"
                      color="primary"
                      icon="attach_money"
                      title="Fractional Ownership"
                      description="Projects providing fractional ownership of real world assets. Eg: Datacenter fractionalizing rack space where each NFT represents a rack and each NFT holder owns the rackspace."
                      // action={{
                      //   type: "external",
                      //   route: "https://www.creative-tim.com/learning-lab/react/overview/material-kit/",
                      //   label: "Let's start",
                      // }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FilledInfoCard
                      // variant="gradient"
                      color="primary"
                      icon="precision_manufacturing"
                      title="Seed Funding"
                      description="Startups raising capital for building the product and each NFT receiving benefits as per the SAFE or KISS agreement between the startup and the NFT holders."
                      // action={{
                      //   type: "external",
                      //   route: "https://www.creative-tim.com/learning-lab/react/overview/datepicker/",
                      //   label: "Read more",
                      // }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FilledInfoCard
                      // variant="gradient"
                      color="primary"
                      icon="account_balance"
                      title="Lending"
                      description="Business with existing revenue looking to raise money for further expansion. Strict eligibility criteria is maintained for lending to safegaurd the investors."
                      // action={{
                      //   type: "external",
                      //   route: "https://www.creative-tim.com/learning-lab/react/overview/datepicker/",
                      //   label: "Read more",
                      // }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FilledInfoCard
                      // variant="gradient"
                      color="primary"
                      icon="art_track"
                      title="Artists"
                      description="Artists raising money for organising concerts and issuing tickets as NFTs or on profit sharing mode. Using our platform artists can promote their concert and sell tickets before the show."
                      // action={{
                      //   type: "external",
                      //   route: "https://www.creative-tim.com/learning-lab/react/overview/datepicker/",
                      //   label: "Read more",
                      // }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FilledInfoCard
                      // variant="gradient"
                      color="primary"
                      icon="corporate_fare"
                      title="Real Estate"
                      description="Commercial real estate projects with each NFT representing a certain Sq.Ft of the rentable commercial space."
                      // action={{
                      //   type: "external",
                      //   route: "https://www.creative-tim.com/learning-lab/react/overview/datepicker/",
                      //   label: "Read more",
                      // }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FilledInfoCard
                      // variant="gradient"
                      color="primary"
                      icon="movie"
                      title="Movie Production"
                      description="Film makers raising capital for production costs and sharing profits with NFT holders. As creative as it can get!"
                      // action={{
                      //   type: "external",
                      //   route: "https://www.creative-tim.com/learning-lab/react/overview/datepicker/",
                      //   label: "Read more",
                      // }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FilledInfoCard
                      // variant="gradient"
                      color="primary"
                      icon="microwave"
                      title="Micro Finance"
                      description="Goverment Registered and approved lending companies can raise money and provide fixed rate of return to NFT holders. We are actively in talks with registered lending companies offring 12% p.a. fixed returns."
                      // action={{
                      //   type: "external",
                      //   route: "https://www.creative-tim.com/learning-lab/react/overview/datepicker/",
                      //   label: "Read more",
                      // }}
                    />
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Projects;
