// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
// import Counters from "pages/Presentation/sections/Counters";
import Information from "pages/Presentation/sections/Information";
import InformationTwo from "pages/Presentation/sections/InformationTwo";
import MKBadge from "components/MKBadge";
// import DesignBlocks from "pages/Presentation/sections/DesignBlocks";
import Pages from "pages/Presentation/sections/Pages";
import Tokenomics from "pages/Presentation/sections/Tokenomics";
import Projects from "pages/Presentation/sections/Projects";
// import Testimonials from "pages/Presentation/sections/Testimonials";
import Download from "pages/Presentation/sections/Download";

// Presentation page components
// import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";
import FeaturesOne from "layouts/sections/page-sections/featuers/components/FeaturesOne";
import FeaturesTwo from "layouts/sections/page-sections/featuers/components/FeaturesTwo";
import Timeline from "layouts/sections/page-sections/featuers/components/Timeline";
// import featuresOneCode from "layouts/sections/page-sections/featuers/components/FeaturesOne/code";
// import View from "layouts/sections/components/View";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Swiper
// import { register } from "swiper";
// import { register } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
// import { Navigation, Pagination } from "swiper";
// import "swiper/swiper.scss"; // core Swiper
// import "swiper/modules/navigation/navigation.scss"; // Navigation module
// import "swiper/modules/pagination/pagination.scss"; // Pagination module
// Images
// import bgImage from "assets/images/bg-presentation.jpg";

function Presentation() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://app.fundrazor.club",
          label: "Visit App",
          color: "info",
        }}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: "linear-gradient(-45deg, #FFFFFF,#81F5FF, #BAD9FF, #81F5FF,#F7F7F6)", // `url(${bgImage})`,#FEE9ED,
          backgroundSize: "cover",
          animation: "gradient 15s ease infinite",
          backgroundColor: "darkkhaki",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="inherit"
              fontFamily="Nunito,sans-serif"
              fontWeight="light"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              fundrazor.{" "}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="inherit"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              Crowd funding platform offering Fractional ownership of real world assests.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                // variant="gradient"
                color="info"
                icon="flag"
                title="Our Mission"
                description="Provide a global platform for efficient allocation of capital with fractional ownership of real world assets!"
                // action={{
                //   type: "external",
                //   route: "https://www.creative-tim.com/learning-lab/react/overview/material-kit/",
                //   label: "Let's start",
                // }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                variant="gradient"
                color="info"
                icon="precision_manufacturing"
                title="Our Focus"
                description="Bring companies and individual investors to a frictionless decentralized platform, enabling fund raising and investment opportunities at a global scale using NFTs." // "Help individiual investors efficiently allocate their capital on projects with great ROI, while helping companies raise money by tapping into individual investors!"
                // action={{
                //   type: "external",
                //   route: "https://www.creative-tim.com/learning-lab/react/overview/datepicker/",
                //   label: "Read more",
                // }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="apps"
                title="Our Strategy"
                description="Use the frictionless and trustless nature of DeFi and unlock the power of NFTs to link real world assets with NFTs!"
                // action={{
                //   type: "external",
                //   route: "https://www.creative-tim.com/learning-lab/react/alerts/material-kit/",
                //   label: "Read more",
                // }}
              />
            </Grid>
          </Grid>
        </Container>
        {/* <Counters /> */}
        {/* <DesignBlocks /> */}
        <MKBox component="section">
          {/* <Container> */}
          <Grid
            container
            item
            xs={12}
            lg={6}
            flexDirection="column"
            alignItems="center"
            sx={{ textAlign: "center", mt: 12, mx: "auto", px: 0.75 }}
          >
            <MKBadge
              variant="contained"
              color="info"
              badgeContent="Opportunities"
              container
              sx={{ mb: 2 }}
            />
            <MKTypography variant="h2" fontWeight="bold">
              Projects going live at launch.
            </MKTypography>
          </Grid>
          {/* </Container> */}
        </MKBox>
        <Swiper
          // spaceBetween={50}
          slidesPerView={1}
          // eslint-disable-next-line no-console
          // onSlideChange={() => console.log("slide change")}
          // eslint-disable-next-line no-console
          // onSwiper={(swiper) => console.log(swiper)}
        >
          <SwiperSlide>
            <Information />
          </SwiperSlide>
          <SwiperSlide>
            <InformationTwo />
          </SwiperSlide>
        </Swiper>
        {/* <View title="Features 1" code={featuresOneCode}> */}
        <FeaturesOne />
        <FeaturesTwo />
        {/* </View> */}
        <Pages />
        <Tokenomics />
        <Timeline />
        <Projects />
        <Download />
        {/* <Container sx={{ mt: 6 }}>
          <BuiltByDevelopers />
        </Container> */}
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                variant="gradient"
                color="info"
                icon="quiz"
                title="FAQs"
                description="Check our FAQ page to learn more about Fundrazor. Few minutes of your time may lead to a great investment decision!"
                action={{
                  type: "external",
                  route: "https://www.creative-tim.com/learning-lab/react/overview/material-kit/",
                  label: "Read FAQs",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                // variant="gradient"
                color="warning"
                icon="contact_mail"
                title="Contact Us"
                description="Want to list your project? Reach out to us to list your project on Fundrazor. All you need is a great idea and a great team!"
                action={{
                  type: "external",
                  route: "https://www.creative-tim.com/learning-lab/react/overview/datepicker/",
                  label: "Contact Us",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                // variant="gradient"
                color="info"
                icon="create_new_folder"
                title="Mint NFTs"
                description="Buy the Fundrazor NFTs and earn passive income from every project that is successfully funded on the platform."
                action={{
                  type: "external",
                  route: "https://www.creative-tim.com/learning-lab/react/alerts/material-kit/",
                  label: "Launch App",
                }}
              />
            </Grid>
          </Grid>
        </Container>
        {/* <Testimonials /> */}
        {/* <Download /> */}
        <MKBox pt={18} pb={6}>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>
                <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                  Follow us on social media!
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  Be part of our growing community.
                </MKTypography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={5}
                my={{ xs: 5, lg: "auto" }}
                mr={{ xs: 0, lg: "auto" }}
                sx={{ textAlign: { xs: "center", lg: "right" } }}
              >
                <MKSocialButton
                  component="a"
                  href="https://twitter.com/intent/tweet?text=Check%20Material%20Design%20System%20made%20by%20%40CreativeTim%20%23webdesign%20%23designsystem%20%23mui5&amp;url=https%3A%2F%2Fwww.creative-tim.com%2Fproduct%2Fmaterial-kit-react"
                  target="_blank"
                  color="twitter"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-twitter" />
                  &nbsp;Tweet
                </MKSocialButton>
                <MKSocialButton
                  component="a"
                  href="https://www.facebook.com/sharer/sharer.php?u=https://www.creative-tim.com/product/material-kit-react"
                  target="_blank"
                  color="facebook"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-facebook" />
                  &nbsp;Share
                </MKSocialButton>
                <MKSocialButton
                  component="a"
                  href="https://www.pinterest.com/pin/create/button/?url=https://www.creative-tim.com/product/material-kit-react"
                  target="_blank"
                  color="pinterest"
                >
                  <i className="fab fa-pinterest" />
                  &nbsp;Pin it
                </MKSocialButton>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
