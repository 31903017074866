/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
// import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Presentation page components
// import ExampleCard from "pages/Presentation/components/ExampleCard";

// Data
// import data from "pages/Presentation/sections/data/pagesData";

function Pages() {
  // const renderData = data.map(({ image, name, route }) => (
  //   <Grid item xs={12} md={6} sx={{ mb: { xs: 3, lg: 0 } }} key={name}>
  //     <Link to={route}>
  //       <ExampleCard image={image} name={name} display="grid" minHeight="auto" />
  //     </Link>
  //   </Grid>
  // ));

  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 2, mx: "auto", px: 0.75 }}
        >
          <MKBadge
            variant="contained"
            color="info"
            badgeContent="Fundrazor 101"
            container
            sx={{ mb: 2 }}
          />
          <MKTypography variant="h2" fontWeight="bold">
            About Fundrazor
          </MKTypography>
          <MKTypography variant="body1" color="text">
            A platform integrating NFTs and real world assests.
          </MKTypography>
        </Grid>
      </Container>
      <Container sx={{ mt: { xs: 8, lg: 12 } }}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
              <MKTypography variant="h3" fontWeight="bold" mb={1}>
                A crowdfunding platform making investments frictionless and trustless!
              </MKTypography>
              <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
                Using the power of DeFi to bring to you amazing investment opportunities.
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={9} sx={{ mt: 3, px: { xs: 0, lg: 8 } }}>
            <Grid container spacing={1}>
              {/* {renderData} */}
              <MKTypography variant="body2" color="text" mb={2}>
                Non Fungible Token (NFT) can be used to uniquely represent any asset and link the
                ownership of the asset to the owner of the NFT. For example, an original painting by
                an artist can be linked to an NFT and a person buying the painting in an auction can
                be assured that he/she is buying the original painting and not some copy by
                transferring the ownership of the NFT associated with the painting to the buyer.
              </MKTypography>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 1, sm: 2, md: 2 }}>
                <MKBox display="flex" alignItems="center" p={2} maxWidth="400px">
                  <MKBox
                    width="1rem"
                    height="1rem"
                    variant="gradient"
                    bgColor="info"
                    color="white"
                    coloredShadow="info"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="xl"
                  >
                    <Icon fontSize="small">addcircle</Icon>
                  </MKBox>
                  <MKTypography variant="body2" color="text" pl={2}>
                    All projects listed on the platform will undergo legal due diligence with
                    inhouse legal team and KYC verification of project team/company.
                  </MKTypography>
                </MKBox>
                <MKBox display="flex" alignItems="center" p={2}>
                  <MKBox
                    width="1rem"
                    height="1rem"
                    variant="gradient"
                    bgColor="info"
                    color="white"
                    coloredShadow="info"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="xl"
                  >
                    <Icon fontSize="small">addcircle</Icon>
                  </MKBox>
                  <MKTypography variant="body2" color="text" pl={2}>
                    Fundrazor follows an all or nothing model where the funds will be transferred to
                    the project only if the 100% funding goal in reached within the stipulated time.
                  </MKTypography>
                </MKBox>
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 1, sm: 2, md: 2 }}>
                <MKBox display="flex" alignItems="center" p={2} maxWidth="400px">
                  <MKBox
                    width="1rem"
                    height="1rem"
                    variant="gradient"
                    bgColor="info"
                    color="white"
                    coloredShadow="info"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="xl"
                  >
                    <Icon fontSize="small">addcircle</Icon>
                  </MKBox>
                  <MKTypography variant="body2" color="text" pl={2}>
                    If the 100% funding goal is not reached, money will be transferred back to the
                    individual investors automatically by the smrt contract.
                  </MKTypography>
                </MKBox>
                <MKBox display="flex" alignItems="center" p={2}>
                  <MKBox
                    width="1rem"
                    height="1rem"
                    variant="gradient"
                    bgColor="info"
                    color="white"
                    coloredShadow="info"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="xl"
                  >
                    <Icon fontSize="small">addcircle</Icon>
                  </MKBox>
                  <MKTypography variant="body2" color="text" pl={2}>
                    Fundrazor will not be holding any money during the fundraising event and all
                    transactions are automatically controlled by the smart contract.
                  </MKTypography>
                </MKBox>
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 1, sm: 2, md: 2 }}>
                <MKBox display="flex" alignItems="center" p={2} maxWidth="400px">
                  <MKBox
                    width="1rem"
                    height="1rem"
                    variant="gradient"
                    bgColor="info"
                    color="white"
                    coloredShadow="info"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="xl"
                  >
                    <Icon fontSize="small">addcircle</Icon>
                  </MKBox>
                  <MKTypography variant="body2" color="text" pl={2}>
                    The inhouse legal team of fundrazor will also take legal action and initiate
                    recovery of funds on projects that violate terms of use and indulge in
                    misappropriation of funds raised through the platform.
                  </MKTypography>
                </MKBox>
                <MKBox display="flex" alignItems="center" p={2}>
                  <MKBox
                    width="1rem"
                    height="1rem"
                    variant="gradient"
                    bgColor="info"
                    color="white"
                    coloredShadow="info"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="xl"
                  >
                    <Icon fontSize="small">addcircle</Icon>
                  </MKBox>
                  <MKTypography variant="body2" color="text" pl={2}>
                    Fundrazor will be making a legal contract with clearly defined rules for the
                    usage of funds raised via the platform allowing for a safe platform for
                    investors to invest with a peace of mind.
                  </MKTypography>
                </MKBox>
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 1, sm: 2, md: 2 }}>
                <MKBox display="flex" alignItems="center" p={2} maxWidth="400px">
                  <MKBox
                    width="1rem"
                    height="1rem"
                    variant="gradient"
                    bgColor="info"
                    color="white"
                    coloredShadow="info"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="xl"
                  >
                    <Icon fontSize="small">addcircle</Icon>
                  </MKBox>
                  <MKTypography variant="body2" color="text" pl={2}>
                    All the money raised for the project will be held in a smart contract with
                    clearly defined rules for fund transfer.
                  </MKTypography>
                </MKBox>
                <MKBox display="flex" alignItems="center" p={2}>
                  <MKBox
                    width="1rem"
                    height="1rem"
                    variant="gradient"
                    bgColor="info"
                    color="white"
                    coloredShadow="info"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="xl"
                  >
                    <Icon fontSize="small">addcircle</Icon>
                  </MKBox>
                  <MKTypography variant="body2" color="text" pl={2}>
                    The rules defined in the smart contract can be checked and verified by the
                    investors on the blockchain before investment.
                  </MKTypography>
                </MKBox>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Pages;
