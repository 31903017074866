/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
// import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Stack from "@mui/material/Stack";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
// import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Presentation page components
// import ExampleCard from "pages/Presentation/components/ExampleCard";

// Data
// import data from "pages/Presentation/sections/data/pagesData";

function Tokenomics() {
  // const renderData = data.map(({ image, name, route }) => (
  //   <Grid item xs={12} md={6} sx={{ mb: { xs: 3, lg: 0 } }} key={name}>
  //     <Link to={route}>
  //       <ExampleCard image={image} name={name} display="grid" minHeight="auto" />
  //     </Link>
  //   </Grid>
  // ));

  return (
    <MKBox component="section" py={2}>
      {/* <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 2, mx: "auto", px: 0.75 }}
        >
          <MKBadge
            variant="contained"
            color="info"
            badgeContent="Fundrazor 101"
            container
            sx={{ mb: 2 }}
          />
          <MKTypography variant="h2" fontWeight="bold">
            About Fundrazor
          </MKTypography>
          <MKTypography variant="body1" color="text">
            A platform integrating NFTs and real world assests.
          </MKTypography>
        </Grid>
      </Container> */}
      <Container sx={{ mt: { xs: 8, lg: 12 } }}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
              <MKTypography variant="h3" fontWeight="bold" mb={1}>
                Fundrazor Economics!
              </MKTypography>
              <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
                Our Business Model explained.
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={9} sx={{ mt: 3, px: { xs: 0, lg: 8 } }}>
            <Grid container spacing={1}>
              {/* {renderData} */}
              <MKTypography variant="body2" color="text" mb={2}>
                We are providing a platform for fractional ownership so it is only fitting that you
                own a fraction of Fundrazor! We have designed our business model in such a way that
                each NFT holder can own a part of the platform and earn income from the platform fee
                charged for successful projects.
              </MKTypography>
              {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 1, sm: 2, md: 2 }}> */}
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <MKBox display="flex" alignItems="center" p={2}>
                    <MKBox
                      width="1rem"
                      height="1rem"
                      variant="gradient"
                      bgColor="info"
                      color="white"
                      coloredShadow="info"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      <Icon fontSize="small">check_circle</Icon>
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                      6000 Total NFTs available for mint.
                    </MKTypography>
                  </MKBox>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <MKBox display="flex" alignItems="center" p={2}>
                    <MKBox
                      width="1rem"
                      height="1rem"
                      variant="gradient"
                      bgColor="info"
                      color="white"
                      coloredShadow="info"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      <Icon fontSize="small">check_circle</Icon>
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                      500 Founders passes offered @$250 per pass.
                    </MKTypography>
                  </MKBox>
                </Grid>
                <Grid item xs={12} lg={6}>
                  {/* </Stack> */}
                  {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 1, sm: 2, md: 2 }}> */}
                  <MKBox display="flex" alignItems="center" p={2} maxWidth="400px">
                    <MKBox
                      width="1rem"
                      height="1rem"
                      variant="gradient"
                      bgColor="info"
                      color="white"
                      coloredShadow="info"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      <Icon fontSize="small">check_circle</Icon>
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                      1500 NFTs sold at $300 per NFT
                    </MKTypography>
                  </MKBox>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <MKBox display="flex" alignItems="center" p={2}>
                    <MKBox
                      width="1rem"
                      height="1rem"
                      variant="gradient"
                      bgColor="info"
                      color="white"
                      coloredShadow="info"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      <Icon fontSize="small">check_circle</Icon>
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                      4000 NFTs sold at $350 per NFT.
                    </MKTypography>
                  </MKBox>
                </Grid>
                <Grid item xs={12} lg={6}>
                  {/* </Stack> */}
                  {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 1, sm: 2, md: 2 }}> */}
                  <MKBox display="flex" alignItems="center" p={2} maxWidth="400px">
                    <MKBox
                      width="1rem"
                      height="1rem"
                      variant="gradient"
                      bgColor="info"
                      color="white"
                      coloredShadow="info"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      <Icon fontSize="small">check_circle</Icon>
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                      500 NFTs distributed free to Founders pass holders
                    </MKTypography>
                  </MKBox>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <MKBox display="flex" alignItems="center" p={2}>
                    <MKBox
                      width="1rem"
                      height="1rem"
                      variant="gradient"
                      bgColor="info"
                      color="white"
                      coloredShadow="info"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      <Icon fontSize="small">check_circle</Icon>
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                      Fundrazor charges projects $100 listing fee and 10% platform fee on successful
                      funding.
                    </MKTypography>
                  </MKBox>
                </Grid>
                <Grid item xs={12} lg={6}>
                  {/* </Stack> */}
                  {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 1, sm: 2, md: 2 }}> */}
                  <MKBox display="flex" alignItems="center" p={2} maxWidth="400px">
                    <MKBox
                      width="1rem"
                      height="1rem"
                      variant="gradient"
                      bgColor="info"
                      color="white"
                      coloredShadow="info"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      <Icon fontSize="small">check_circle</Icon>
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                      50% platform fee distributed to all NFT Holders.
                    </MKTypography>
                  </MKBox>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <MKBox display="flex" alignItems="center" p={2}>
                    <MKBox
                      width="1rem"
                      height="1rem"
                      variant="gradient"
                      bgColor="info"
                      color="white"
                      coloredShadow="info"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      <Icon fontSize="small">check_circle</Icon>
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                      1% additional dividend to founder pass holders
                    </MKTypography>
                  </MKBox>
                </Grid>
                <Grid item xs={12} lg={6}>
                  {/* </Stack> */}
                  {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 1, sm: 2, md: 2 }}> */}
                  <MKBox display="flex" alignItems="center" p={2} maxWidth="400px">
                    <MKBox
                      width="1rem"
                      height="1rem"
                      variant="gradient"
                      bgColor="info"
                      color="white"
                      coloredShadow="info"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      <Icon fontSize="small">check_circle</Icon>
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                      1% of platform fee transferred to legal wallet towards legal expenses.
                    </MKTypography>
                  </MKBox>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <MKBox display="flex" alignItems="center" p={2}>
                    <MKBox
                      width="1rem"
                      height="1rem"
                      variant="gradient"
                      bgColor="info"
                      color="white"
                      coloredShadow="info"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      <Icon fontSize="small">check_circle</Icon>
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                      Balance 48% of platform fee for operation expenses.
                    </MKTypography>
                  </MKBox>
                </Grid>
                <Grid item xs={12} lg={6}>
                  {/* </Stack> */}
                  {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 1, sm: 2, md: 2 }}> */}
                  <MKBox display="flex" alignItems="center" p={2} maxWidth="400px">
                    <MKBox
                      width="1rem"
                      height="1rem"
                      variant="gradient"
                      bgColor="info"
                      color="white"
                      coloredShadow="info"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      <Icon fontSize="small">check_circle</Icon>
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                      If all NFTs are soldout within 2 months of mint, 80% of platform fee will be
                      distributed to NFT holders instead of 50% for first 1 year to accelerate ROI.
                    </MKTypography>
                  </MKBox>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <MKBox display="flex" alignItems="center" p={2}>
                    <MKBox
                      width="1rem"
                      height="1rem"
                      variant="gradient"
                      bgColor="info"
                      color="white"
                      coloredShadow="info"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      <Icon fontSize="small">check_circle</Icon>
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                      All NFTs are tradeable in the marketplace, offering liquidity to your
                      investment.
                    </MKTypography>
                  </MKBox>
                </Grid>
                {/* </Stack> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Tokenomics;
