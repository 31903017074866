/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";
// import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import CustomTimeline from "layouts/sections/components/CustomTimeline";

function Timeline() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={5}>
            <MKTypography variant="h3" my={1}>
              Project Timeline
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={2}>
              While we have an ambitious timeline about the project, we wanted to keep our timelines
              realistic. Each milestone is designed to add major features to the project keeping in
              mind the expected growth of the project. While some features enhance user experience,
              many are aimed at providing more investment opportunities and make investments more
              attractive.
            </MKTypography>
            {/* <MKTypography
              component="a"
              href="https://opensea.io/learn/what-are-nfts"
              variant="body2"
              color="info"
              fontWeight="regular"
              target="_blank"
              sx={{
                width: "max-content",
                display: "flex",
                alignItems: "center",

                "& .material-icons-round": {
                  fontSize: "1.125rem",
                  transform: "translateX(3px)",
                  transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                },

                "&:hover .material-icons-round, &:focus .material-icons-round": {
                  transform: "translateX(6px)",
                },
              }}
            >
              Read more
              <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
            </MKTypography> */}
          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <CustomTimeline />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Timeline;
